import React from "react";
import { useEffect } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo.js";
import OPostOut from "../../components/opostOut.js";
import OPostIcons from "../../components/opostIcons.js";
import { Link, graphql } from "gatsby";
import { gsap } from "gsap";
import Newsletter from "../../components/newsletter.js";
import OfferDetails from "../../components/offerDetails.js";
import CTA from "../../components/cta.js";

const OfferPage = props => {
  const {
    pageContext: { first, group, index, last }
  } = props;
  const previousUrl = index - 1 === 1 ? "" : (index - 1).toString();
  const nextUrl = (index + 1).toString();

  useEffect(() => {
    gsap.to("#a1", { x: 500, duration: 0 });
    gsap.to("#a1", { x: 0, duration: 5 });
    gsap.to("#a2", { x: -500, duration: 0 });
    gsap.to("#a2", { x: 50, duration: 5, delay: 1 });
  }, []);

  return (
    <>
      <Layout>
        <SEO title="Oferta" />
        <div className="x-wrapper">
          <div
            className="columns"
            style={{
              minHeight: "500px",
              padding: "0px",
              margin: "0px",
              alignItems: "center",
              backgroundColor: "white"
            }}
          >
            <div
              id="lcol"
              className="column nopad"
              style={{
                justifyContent: "center",
                padding: "2% 0%",
                maxWidth: "25%"
              }}
            >
              <div className="nopadleft" style={{ paddingLeft: "3rem" }}>
                <h1
                  style={{
                    textTransform: "uppercase",
                    fontSize: "2em",
                    marginTop: "30px"
                  }}
                  className="title"
                >
                  <strong style={{ color: "#00ff00" }}>OFERTA </strong>
                  <br />
                  <span style={{ fontSize: ".5em" }}>
                    {" "}
                    POZNAJ SZCZEGÓŁY OFEROWANYCH
                    <br /> PRZEZ NAS USŁUG
                  </span>
                </h1>
                <hr
                  style={{
                    maxWidth: "150px",
                    backgroundColor: "#333333",
                    height: "3px"
                  }}
                />
                <div
                  className="show-mob"
                  style={{ textAlign: "center", width: "100%" }}
                >
                  <img
                    id="mobile-x"
                    src="/img/x.svg"
                    style={{
                      marginBottom: "20px",
                      maxWidth: "500px",
                      marginTop: "-75px"
                    }}
                    width="auto"
                    height="auto"
                    alt="Rexer engineering"
                  />
                </div>
                <p>
                  Specjalizujemy się w{" "}
                  <strong>instalacjach wewnętrznych jak i sieciach</strong>.
                  Projektujemy, wykonujemy, serwisujemy. <br />
                  <br />
                  Kompleksowość, zorientowana na niezawodność w działaniu i
                  oszczędność zużywanych mediów oraz energii.
                </p>
                <a href="#main" className="button rexer-button">
                  {" "}
                  Poznaj naszą ofertę
                </a>
              </div>
              <br />
              <div
                className="column mob-hide is-two-thirds"
                id="a2"
                style={{ position: "relative" }}
              >
                <div
                  className="columns"
                  style={{
                    padding: "1% 7%",
                    marginTop: "-3em",
                    textAlign: "center",
                    minHeight: "380px",
                    alignItems: "center",
                    marginRight: "25%"
                  }}
                >
                  <OPostIcons />
                </div>
              </div>
            </div>

            <div
              id="midcol"
              className="column"
              style={{ width: "50%", marginTop: "-100px", textAlign: "center" }}
            >
              <img
                src="/img/x.svg"
                style={{ marginBottom: "0" }}
                width="auto"
                height="auto"
                alt="Rexer engineering"
              />
              <p
                id="paddmob"
                style={{ textAlign: "center", marginTop: "-20px" }}
              >
                <a
                  href="#main"
                  style={{ border: "0px solid white", marginTop: "0px" }}
                  className="button standard-button"
                >
                  ↓
                </a>
              </p>
            </div>

            <div
              id="rcol"
              className="column"
              style={{
                maxWidth: "25%",
                margin: "0px",
                marginTop: "-175px",
                padding: "0px",
                alignItems: "end",
                display: "block",
                justifyContent: "center",
                marginRight: "3em"
              }}
            >
              <div style={{ textAlign: "right", width: "100%" }}>
                <img
                  id="a1"
                  src="/img/w1.png"
                  width="100%"
                  height="auto"
                  alt="Rexer engineering"
                  style={{
                    minWidth: "300px",
                    maxWidth: "400px",
                    opacity: "1",
                    marginBottom: "0px",
                    borderBottom: "3px solid #f6f6f6",
                    backgroundImage: "url(/img/hero-bg-home.png)",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center"
                  }}
                />
              </div>
              <div
                className="deco-w"
                style={{
                  border: "5px solid #333333",
                  padding: "20px",
                  marginTop: "3em",
                  marginLeft: "1rem"
                }}
              >
                <p
                  className="deco"
                  style={{ textAlign: "right", backgroundColor: "white" }}
                >
                  Stawiamy na poszukiwanie i wdrażanie najnowocześniejszych
                  rozwiązań organizacyjnych i technicznych.
                </p>
              </div>
              <p
                id="marginmob"
                style={{ textAlign: "right", marginTop: "3em" }}
              >
                <a className="button rexer-button" href="#details">
                  Poznaj szczegółową ofertę
                </a>
              </p>
            </div>
          </div>
        </div>

        <div
          id="main"
          className="is-bold"
          style={{
            textAlign: "left",
            backgroundColor: "#f6f6f6",
            padding: "5%"
          }}
        >
          <h2
            className="subtitle is-size-5"
            style={{
              borderLeft: "5px solid #00ff00",
              marginTop: "50px",
              paddingLeft: "10px",
              marginLeft: "-10px",
              textTransform: "uppercase"
            }}
          >
            OFERUJEMY <b>KOMPLEKSOWĄ OBSŁUGĘ</b> W BRANŻY INŻYNIERII ŚRODOWISKA
          </h2>
          <br />
          <p className="is-size-5" style={{ fontWeight: "400" }}>
            Stawiamy na dobre relacje i <b>partnerskie podejście</b>, dzięki
            czemu jesteśmy w stanie spełnić funkcjonalne i{" "}
            <b>jakościowe oczekiwania naszych Klientów</b>.<br />
            <br />
            Przygotujemy propozycje rozwiązań, które <b>podniosą wartość</b>,
            użyteczność i efektywność Twoich inwestycji budowlanych w
            następujących zakresach:
          </p>
        </div>
        <div
          className="columns"
          style={{ padding: "1% 2%", marginTop: "-2em", textAlign: "center" }}
        >
          <OPostOut posts={group} />
        </div>
        {/*<div className='buttons is-centered' style={{marginTop:'-100px'}}>
        <PaginationLink test={first} url={previousUrl} text='<' />
        <PaginationLink test={last} url={nextUrl} text='>' />
      </div>*/}

        <div
          id="details"
          className="section"
          style={{ display: "block", position: "relative" }}
        >
          <h2
            className="subtitle is-size-4"
            style={{
              borderLeft: "5px solid #00ff00",
              fontWeight: "600",
              textTransform: "uppercase",
              marginTop: "50px",
              paddingLeft: "10px"
            }}
          >
            Szczegółowy zakres naszej oferty
            <br />
          </h2>
          <br />
          <p>
            Chętnie dzielimy się naszą wiedzą i doświadczeniem, aby budować
            wzajemne relacje oraz optymalizować proces projektowania, a więc i
            samej budowy. Do każdego Klienta podchodzimy po partnersku oraz poza
            usługami projektowymi oferujemy również:
          </p>
        </div>
        <OfferDetails />
        <br />
        <br />

        {/*<div className="columns">
      <div className="is-bold column" style={{textAlign:'center', padding:'0% 5% 2.5% 5%'}}>
      <p className="is-size-3">Zobacz nasze realizacje</p>
      <p>Sprawdź jakie efekty przyniosły nasze prace.</p>
      <br />
      <Link className="button rexer-button is-size-5" to="/realizacje/">
        Realizacje
      </Link>
      </div>

      <div className="is-bold column" style={{textAlign:'center', padding:'0% 5% 2.5% 5%'}}>
      <p className="is-size-3">Przeczytaj aktualności</p>
      <p>Życzymy miłej lektury.</p>
      <br />
      <Link className="button rexer-button is-size-5" to="/aktualnosci/">
        Aktualności
      </Link>
      </div>
      </div>
      <br /><br />

      <div style={{textAlign:'center',paddingBottom:'5%'}}>

      <p>Wróć do przeglądania</p>
      <br />
      <Link className="button rexer-button is-size-5" to="/">
      Home
      </Link>
      </div>*/}

        <Newsletter />
        <CTA />
      </Layout>
    </>
  );
};

export default OfferPage;

export const oopageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD. MM. YYYY")
            title
            language
            description
            templateKey
            image
          }
        }
      }
    }
  }
`;
