import React from "react";
import { Link } from "gatsby";

class OPostOut extends React.Component {
  render() {
    return (
      <>
        <div
          style={{
            margin: "2em 0 4em",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center"
          }}
        >
          <Link
            className="box o-box o-big"
            to="/oferta/projektowanie/"
            style={{
              margin: "3%",
              minWidth: "250px",
              width: "27%",
              padding: "20px",
              border: "1px solid black",
              borderRadius: "10px"
            }}
          >
            <div
              style={{ textAlign: "center", display: "block", width: "100%" }}
            >
              <img
                className="o-icon"
                style={{ borderRadius: "0px", width: "50px", margin: "auto" }}
                alt="Rexer"
                loading="lazy"
                src="/assets/projektowanie-icon.svg"
              />
            </div>
            <br />
            <div>
              <h3
                className="is-size-5"
                style={{ textAlign: "center", minHeight: "50px" }}
              >
                <span
                  style={{
                    color: "#00ff00",
                    boxShadow: `none`,
                    fontWeight: "800",
                    textTransform: "uppercase"
                  }}
                >
                  Projektowanie
                </span>
              </h3>
              <br />
              <h4 className="is-size-6" style={{ textTransform: "uppercase" }}>
                AUDYT, PROJEKT, COMMISSIONING
              </h4>
              <br />
              <p className="is-size-6" style={{ textAlign: "center" }}>
                Wykonujemy projekty zarówno pojedynczych zakresów jak i całego
                pakietu - od instalacji wodnych, kanalizacyjnych i grzewczych,
                po wentylacje, klimatyzacje oraz instalacje technologiczne.
              </p>
            </div>
          </Link>
          <Link
            className="box o-box o-big"
            to="/oferta/generalne-wykonawstwo/"
            style={{
              margin: "3%",
              minWidth: "250px",
              width: "27%",
              padding: "20px",
              border: "1px solid black",
              borderRadius: "10px"
            }}
          >
            <div
              style={{ textAlign: "center", display: "block", width: "100%" }}
            >
              <img
                className="o-icon"
                style={{ borderRadius: "0px", width: "50px", margin: "auto" }}
                alt="Rexer"
                loading="lazy"
                src="/assets/wykonastwo-icon.svg"
              />
            </div>
            <br />
            <div>
              <h3
                className="is-size-5"
                style={{ textAlign: "center", minHeight: "50px" }}
              >
                <span
                  style={{
                    color: "#00ff00",
                    boxShadow: `none`,
                    fontWeight: "800",
                    textTransform: "uppercase"
                  }}
                >
                  Generalne wykonawstwo
                </span>
              </h3>
              <br />
              <h4 className="is-size-6" style={{ textTransform: "uppercase" }}>
                PEŁNA OBSŁUGA PROJEKTÓW
              </h4>
              <br />
              <p className="is-size-6" style={{ textAlign: "center" }}>
                REXER to przede wszystkim oferta wykonawstwa instalacji
                sanitarnych i HVAC - cechuje nas pewność i profesjonalizm.
              </p>
            </div>
          </Link>
          <Link
            className="box o-box o-big"
            to="/oferta/serwis/"
            style={{
              margin: "3%",
              minWidth: "250px",
              width: "27%",
              padding: "20px",
              border: "1px solid black",
              borderRadius: "10px"
            }}
          >
            <div
              style={{ textAlign: "center", display: "block", width: "100%" }}
            >
              <img
                className="o-icon"
                style={{ borderRadius: "0px", width: "50px", margin: "auto" }}
                alt="Rexer"
                loading="lazy"
                src="/assets/serwis-icon.svg"
              />
            </div>
            <br />
            <div>
              <h3
                className="is-size-5"
                style={{ textAlign: "center", minHeight: "50px" }}
              >
                <span
                  style={{
                    color: "#00ff00",
                    boxShadow: `none`,
                    fontWeight: "800",
                    textTransform: "uppercase"
                  }}
                >
                  Serwis
                </span>
              </h3>
              <br />
              <h4 className="is-size-6" style={{ textTransform: "uppercase" }}>
                utrzymanie obiektów
              </h4>
              <br />
              <p className="is-size-6" style={{ textAlign: "center" }}>
                Doskonałe działanie każdej instalacji wymaga regularnych,
                kompleksowych przeglądów oraz szybkich napraw.
              </p>
            </div>
          </Link>
        </div>
      </>
    );
  }
}

export default OPostOut;
