import React from 'react';
import { Link } from 'gatsby'


class OPostIcons extends React.Component {

  render() {

  return (
    <>
    <Link to="/oferta/projektowanie/" className="column" style={{textAlign:'center',display:'block',width:'100%'}}>
      <img className="o-icon o-hover" loading="lazy" style={{borderRadius:'0px',maxWidth:'100px',margin:'auto'}} alt="Rexer"  src="/assets/projektowanie-icon.svg" />
    </Link>
    <Link to="/oferta/generalne-wykonawstwo/" className="column" style={{textAlign:'center',display:'block',width:'100%'}}>
      <img className="o-icon o-hover" loading="lazy" style={{borderRadius:'0px',maxWidth:'100px',margin:'auto'}} alt="Rexer"  src="/assets/wykonastwo-icon.svg" />
    </Link>
    <Link to="/oferta/serwis/" className="column" style={{textAlign:'center',display:'block',width:'100%'}}>
      <img className="o-icon o-hover" loading="lazy" style={{borderRadius:'0px',maxWidth:'100px',margin:'auto'}} alt="Rexer"  src="/assets/serwis-icon.svg" />
    </Link>
    </>
  )
}
}

export default OPostIcons
